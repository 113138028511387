import { render, staticRenderFns } from "./collection-menu.vue?vue&type=template&id=1976ff60&scoped=true&"
import script from "./collection-menu.vue?vue&type=script&lang=js&"
export * from "./collection-menu.vue?vue&type=script&lang=js&"
import style0 from "./collection-menu.vue?vue&type=style&index=0&id=1976ff60&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../MultiSiteClient/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1976ff60",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../MultiSiteClient/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VExpandTransition,VImg})
